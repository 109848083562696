<template>
  <div class="fu-wrapper">
    <label>{{ $attrs.title }}</label>
    <span class="formats">{{ $attrs.accept }}</span>
    <div class="upload-box" :class="[isFileProcessingInProgress ? 'purple' : '']" :style="[backgroundImage, borderBox]">
      <div v-if="isUploadedFileAnImage" class="overlay"></div>
      <div v-if="fileUrl" class="download-wrapper">
        <div v-if="fileExists && isUploadedFileAnAudio" class="icon-wrapper">
          <i class="fa fa-microphone"></i>
        </div>

        <div v-if="fileExists && isUploadedFileAVideo" class="icon-wrapper">
          <i class="fa fa-video-camera"></i>
        </div>

        <a class="primary button dropdown" @click="toggleDropdown">
          Download
        </a>
        <div class="dropdown-content" v-show="showDropdown" v-click-outside="hideDropdown">
          <h4>Available formats</h4>
          <ul>
            <li class="download-link" v-for="(url, type) in fileUrls" :key="type">
              <a target="_blank" download :href="url">{{ type }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="fileProcessingStatus == 'error'" class="callout alert">
        There was an error processing your file. Try again or contact a developer.
      </div>

      <div v-if="isFileProcessingInProgress" class="processing">
        <smm-loading-animation/>
        <p>Processing file... please wait. <br>Remember to Save.</p>
      </div>

      <div v-else-if="isUploadInProgress">
        <p>Uploading File... please wait.</p>
        <div class="primary progress">
          <div class="progress-meter" :style="{ width: progress + '%' }"></div>
        </div>
      </div>


      <div v-else-if="!fileExists">
        <label :for="fileInputId" class="button hollow">
          <i class="fa fa-upload"></i>
            Select file
        </label>
        <input :id="fileInputId" :accept="$attrs['accept']" type="file" @change="upload" class="show-for-sr" />
      </div>

      <div class="status-icon">
        <i v-if="hasUnsavedChanges && !isFileProcessingInProgress && !isUploadInProgress" class="fa fa-info-circle" title="File hasn't been saved"></i>
        <i v-if="fileExists && !hasUnsavedChanges" class="fa fa-check-circle"></i>
        <i v-if="fileExists" @click.prevent="removeFile" class="fa fa-times-circle" title="Delete file"></i>
      </div>
    </div>
    <p v-if="fileHasErrors && !isFileProcessingInProgress && !fileExists && !isUploadInProgress && !hasUnsavedChanges" class="status-text error">{{ $attrs.error }}</p>
    <p v-if="hasUnsavedChanges && !isFileProcessingInProgress && !isUploadInProgress" class="status-text">Unsaved changes. Please update or create this {{ $attrs["object_type"].replace(/_/g, " ") }}.</p>

  </div>
</template>

<script>
import axios from "axios";
import ClickOutside from 'vue-click-outside';
import loadingAnimation from './loading_animation';

export default {
  created: function() {
    this.gatewayAuthConfig = {
      headers: {
        Authorization: `Bearer ${this.JWT}`
      }
    };

    const upload_id = this.$attrs["upload_id"];
    if (upload_id) this.checkFileStatus(upload_id);
  },

  data() {
    return {
      progress: 0,
      fileProcessingStatus: null,
      fileInputId: Math.random(),
      fileUrls: {},
      showDropdown: false,
      currentFileuploadId: ''
    };
  },

  components: {
    'smm-loading-animation': loadingAnimation
  },

  directives: {
    ClickOutside
  },

  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },

  methods: {
    upload: async function(event) {
      const files = event.target.files;
      if (!files.length) return;
      const file = files[0];

      try {
        // Upload file to google storage
        const fileExtension = file.name.split(".").pop();
        const file_url = await this.getSignedUrlFromBackend(fileExtension);
        await axios.put(file_url, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: progressEvent => {
            this.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }
        });

        // Tell file manager to process the file
        const {
          data: { upload_id }
        } = await axios.post(
          this.API_ENDPOINTS.SERVICE_GATEWAY + "/v2/admin/files/manager/upload",
          {
            file_url,
            template: this.$attrs["template"],
            object_type: this.$attrs["object_type"],
            object_field: this.$attrs["object_field"],
            object_id: this.$attrs["object_id"]
          },
          this.gatewayAuthConfig
        );

        // Output the upload id to the rails form
        document.getElementById(this.inputId).value = upload_id;

        // Poll the file manager to show when the file is processed
        this.fileProcessingStatus = "accepted";
        this.checkFileStatus(upload_id);
      } catch (error) {
        console.error(error);
        alert("Failed uploading because: " + error);
      }

      this.progress = 0;
    },

    checkFileStatus: async function(upload_id) {
      // "status": "accepted" | "processing" | "completed" | "error" | "not_found"
      const {
        data: {
          data: { status, file_urls }
        }
      } = await axios.get(
        this.API_ENDPOINTS.SERVICE_GATEWAY + "/v2/admin/files/manager/" + upload_id + "/status",
        this.gatewayAuthConfig
      );

      this.currentFileuploadId = upload_id;
      this.fileProcessingStatus = status;
      if (this.isFileProcessingInProgress) {
        setTimeout(() => this.checkFileStatus(upload_id), 1500);
      } else {
        this.fileUrls = file_urls;
      }
    },

    getSignedUrlFromBackend: async function(fileType) {
      const response = await axios.get(
        this.API_ENDPOINTS.SERVICE_GATEWAY + "/v2/admin/files/manager/sign?file_type=" + fileType,
        this.gatewayAuthConfig
      );
      return response.data.data.signed_url;
    },

    removeFile: function() {
      document.getElementById(this.inputId).value = null
      this.fileUrls = {}
      this.currentFileuploadId = ''
    },

    hideDropdown: function() {
      this.showDropdown = false
    },

    toggleDropdown: function() {
      this.showDropdown = !this.showDropdown
    },
  },

  computed: {
    isUploadInProgress: function() {
      return this.progress > 0;
    },

    isFileProcessingInProgress: function() {
      return ["accepted", "processing"].includes(this.fileProcessingStatus);
    },

    isUploadedFileAnImage: function() {
      return /(.png|.jpg|.jpeg|.svg)$/i.test(this.fileUrl);
    },

    isUploadedFileAnAudio: function() {
      return /(.mp3|.wav|.aac|.mpeg|.xwav)$/i.test(this.fileUrl);
    },

    isUploadedFileAVideo: function() {
      return /(.mp4)$/i.test(this.fileUrl);
    },

    hasUnsavedChanges: function() {
      const uploadID = this.$attrs.upload_id || ''
      const currentUploadID = this.currentFileuploadId || ''
      return uploadID != currentUploadID
    },

    fileExists: function() {
      return !!this.fileUrl;
    },

    backgroundImage: function() {
      return this.isUploadedFileAnImage ? {
        backgroundImage: 'url(' + this.fileUrl + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        } : {}
    },

    borderBox: function() {
      if (this.fileExists || this.isUploadInProgress) {
        return { border: '2px solid #3c38ca' }
      } else if (this.fileHasErrors && !this.fileProcessingStatus) {
        return { border: '2px solid #ca3855' }
      } else {
        return {}
      }
    },

    fileHasErrors: function() {
      return this.$attrs["error"]
    },

    inputId: function() {
      return `${this.$attrs["object_type"]}_${this.$attrs["object_field"]}_upload_id`
    },

    fileUrl: function() {
      return this.fileUrls.original || this.fileUrls.default || this.fileUrls.url
    }
  }
};
</script>

<style scoped lang="scss">
$primary: #3c38ca;
$error: #ca3855;
$warning: #ffae00;

.fu-wrapper {
  margin-bottom: 30px;
}

.formats {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: -4px;
  margin-bottom: 10px;
  display: block;
  color: #7a7a7a;
}

.upload-box {
  background-color: #F6F6F6;
  text-align: center;
  border: dashed #CACACA 2px;
  padding: 15px;
  margin-bottom: 10px;
  min-height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    border-color: $primary;

    .status-icon {
      .fa-times-circle {
        display: block;

        &:hover {
          color: $error;
        }
      }

      .fa-check-circle,
      .fa-info-circle {
        display: none;
      }
    }
  }

  &.purple {
    background-color: $primary;
    border-color: $primary;

    p {
      color: white;
    }
  }

  .button {
    margin: 0;
  }

  label.button {
    border: 0;

    .icon {
      margin-right: 10px;
    }
  }

  .progress {
    margin-bottom: 0;
  }

  .download-wrapper {
    z-index: 1;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.6;
    position: absolute;
  }
}

.dropdown-content {
  position: absolute;
  left: 50%;
  z-index: 10;
  padding: 1rem;
  border: 1px solid $primary;
  border-radius: 2px;
  background-color: #fefefe;
  font-size: 1rem;
  transform: translateX(-50%);

  ul {
    margin: 0;

    li {
      list-style: none;
      margin-top: 4px;
    }
  }
}

// status text and icons
.status-text {
  margin: 0;
  font-size: 14px;
  color: $warning;

  &.error {
    color: $error;
  }
}

.status-icon {
  position: absolute;
  right: -12px;
  top: -14px;

  i {
    font-size: 26px;
    background: white;
    border-radius: 100%;
    border: 2px white solid;
    border-bottom: none;
    cursor: pointer;
    color: $primary;
  }

  .fa-times-circle {
    display: none;
  }

  .fa-info-circle {
    color: $warning;
  }

  .fa-info-circle {
    &:hover {

      & ~ .fa-times-circle {
        display: block;
      }
    }
  }
}

// audio video icons
.icon-wrapper {
    border: 2px solid $primary;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;

    i {
      font-size: 40px;
      color: $primary;
    }
}

// loading text
.processing {
  p {
    margin: 0;
  }
}
</style>
