<template>
  <div class="loading">
    <div class="double-bounce1" :style="bgColorStyle"></div>
    <div class="double-bounce2" :style="bgColorStyle"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  props: {
    bgColor: {
      type: String,
      default: '#fff',
    }
  },

  computed: {
    bgColorStyle: function() {
      return { "background-color": this.bgColor }
    }
  }
}
</script>

<style scoped lang="scss">
// loading animation
.loading {
  height: 50px;
  width: 50px;
  margin: 0 auto 10px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2.0s infinite ease-in-out;
  animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

// keyframes
@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
      transform: scale(1);
      -webkit-transform: scale(1);
  }
}
</style>
