import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import FileUpload from "./components/file_upload.vue";

Vue.use(TurbolinksAdapter);

// This is a global mixin, it is applied to every vue instance
Vue.mixin({
  data() {
    return {
      get API_ENDPOINTS() {
        return {
          SERVICE_GATEWAY: window.SERVICE_GATEWAY_API_URL
        };
      },
      get JWT() {
        return window.JWT;
      }
    };
  }
})
/* eslint-disable */
document.addEventListener("turbolinks:load", () => {
  const elements = document.getElementsByClassName('vue');
  for(let el of elements){
    new Vue({
      el: el,
      components: {
        fileupload: FileUpload
      }
    });
  }

});
/* eslint-enable */
